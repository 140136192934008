import React, {useState} from "react"
import {Avatar} from "../../../components/Elements/appUtils";
import ActivityModal from "../modals/activityModal";
import NoteModal from "../modals/noteModal";
import DocumentModal from "../modals/documentModal";
import {useNavigate} from "react-router-dom";
import UserRightComponent from "../../../userRights";

const StudentAction = (props) => {
    const navigate = useNavigate();
    let {record, onRefresh = () => null} = props;
    const [activityModal, setActivityModal] = useState({
        applicationId: '',
        visible: false
    })
    const [documentModal, setDocumentModal] = useState({
        applicationId: '',
        studentId: '',
        visible: false
    })
    const [noteModal, setNoteModal] = useState({
        applicationId: '',
        studentId: '',
        visible: false
    })
    const events = {
        showActivityModal: async (application) => {
            setActivityModal({
                applicationId: application._id,
                studentId: application.studentId,
                visible: true
            })
        },
        hideActivityModal: async () => {
            setActivityModal({
                applicationId: '',
                studentId: '',
                visible: false
            })
        },
        showDocumentModal: async (application) => {
            setDocumentModal({
                applicationId: application._id,
                studentId: application.studentId,
                visible: true
            })
        },
        hideDocumentModal: async () => {
            setDocumentModal({
                applicationId: '',
                studentId: '',

                visible: false
            })
            onRefresh()
        },
        showNoteModal: async (application) => {
            setNoteModal({
                applicationId: application._id,
                studentId: application.studentId,
                visible: true
            })
        },
        hideNoteModal: async () => {
            setNoteModal({
                applicationId: '',
                studentId: '',
                visible: false
            })
            onRefresh()
        }
    }
    const handleClick = () => {
        navigate(`/profile?applicationId=${record._id}`, {
            state: { studentId: record.studentId }
        });
    };

    return (
        <>
            <div className="d-flex justify-content-start align-items-center user-name">
                <Avatar name={record.firstName} size={'sm'}/>
                <div className="d-flex flex-column">
                    <a className={'align-left cursor-pointer'}
                       onClick={handleClick}>
                        {record.firstName} {record.lastName ? record.lastName : ""}</a>
                    <div className="student_icons">
                        <a onClick={() => events.showNoteModal(record)}>
                            <i className="bx bx-comment-dots bx-sm text-primary"/>
                        </a>
                        <a onClick={() => events.showActivityModal(record)}>
                            <i className="bx bx-history bx-sm text-danger"/>
                        </a>
                        <UserRightComponent route={{right: "documents", rightType: "view"}} userTypes={['team']}>
                        <a onClick={() => events.showDocumentModal(record)}>
                            <i className="bx bxs-file bx-sm text-secondary"/>
                        </a>
                        </UserRightComponent>
                    </div>
                </div>
            </div>

            {activityModal.visible ?
                <ActivityModal
                    onClose={events.hideActivityModal}
                    {...activityModal}/> : null}
            {noteModal.visible ?
                <NoteModal
                    onClose={events.hideNoteModal}
                    {...noteModal}/> : null}
            {documentModal.visible ?
                <DocumentModal
                    onClose={events.hideDocumentModal}
                    {...documentModal}/> : null}

        </>
    )
}
export default StudentAction
