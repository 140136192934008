import {customAxios as axios, getToken} from "../../../request";
import {
    activityListUrl,
    addStudentUrl,
    applicationListUrl,
    generateLoaUrl,
    singleApplicationUrl,
    studentListUrl,
    addNoteUrl,
    NoteListUrl,
    appInvoiceListUrl,
    getSingleStudentUrl,
    updateStudentUrl,
    updateCourseUrl,
    applicationUpdateAgentUrl,
    addActivityUrl,
    updateAgentUrl,
    updateIntakeUrl,
    generateOfferLetterUrl,
    requiredDocumentsUrl,
    updateStudentImageUrl,
    addMoreAppUrl,
    updateApplicationStatusUrl,
    generateContractUrl,
    reGenerateOfferLetterUrl,
    palRequestUrl, checkDocumentRequirementUrl, uploadPalUrl, generateCoOpUrl,
    uploadLoaUrl, uploadOfferLetterUrl, uploadCoOpUrl, loaRequestUrl, uploadReceiptUrl,
    offerLetterRequestUrl, coopLetterRequestUrl
} from "../api";
import {notification} from "../../../components/Elements/appUtils";
import {hideLoader, showLoader} from "../../../actions/loader";
import validate from "react-style-editor/lib/utils/validate";

export const addStudentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addStudentUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};
export const addMoreApplicationFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addMoreAppUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const studentListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.get(studentListUrl(), {params: valData})
    dispatch(hideLoader())
    return data.data;
}

export const applicationListFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.get(applicationListUrl(), {params: valData})
    dispatch(hideLoader())
    return data.data;
}

export const singleApplicationFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(singleApplicationUrl(), valData)
    dispatch(hideLoader())
    return data.data;
}


export const generateLoaFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(generateLoaUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const generateCo_opFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(generateCoOpUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const activityListFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.get(activityListUrl(), {params: valData})
    dispatch(hideLoader())
    return data;
}
export const addNoteFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addNoteUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};
export const noteListFxn = async (filters) => {
    let config = {
        params: {...filters}
    }
    let {data} = await axios.get(NoteListUrl(), config);
    return data;
};

export const appInvoiceListFxn = async (filters) => {
    let config = {
        params: {...filters}
    }
    let {data} = await axios.get(appInvoiceListUrl(), config);
    return data;
};


export const singleStudentDetailFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData}
    }
    let {data} = await axios.get(getSingleStudentUrl(), config);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error in loading data'
        })
    }
    return data;
};
export const updateStudentDetailFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(updateStudentUrl(), valData, await getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'data updated'
        })
    } else {
        notification.error({
            message: data.message || 'Error in updating'
        })
    }
    return data;
};
export const updateStudentImageFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(updateStudentImageUrl(), valData, await getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'data updated'
        })
    } else {
        notification.error({
            message: data.message || 'Error in updating'
        })
    }
    return data;
};

export const updateCourseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(updateCourseUrl(), {...valData})
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'data updated'
        })
    } else {
        notification.error({
            message: data.message || 'Error in updating'
        })
    }
    return data;
}

export const updateAgentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateAgentUrl(), valData);
    dispatch(hideLoader());
    if (!data.error) {
        notification.success({
            message: data.message || 'data updated'
        })
    } else {
        notification.error({
            message: data.message || 'Error in updating'
        })
    }
    return data;
}

export const updateIntakeFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateIntakeUrl(), valData);
    dispatch(hideLoader());
    if (!data.error) {
        notification.success({
            message: data.message || 'data updated'
        })
    } else {
        notification.error({
            message: data.message || 'Error in updating'
        })
    }
    return data;
}


export const applicationUpdateAgentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(applicationUpdateAgentUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'data updated'
        })
    } else {
        notification.error({
            message: data.message || 'Error in updating'
        })
    }
    return data;
};

export const updateApplicationStatusFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(updateApplicationStatusUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'data updated'
        })
    } else {
        notification.error({
            message: data.message || 'Error in updating'
        })
    }
    return data;
};

export const generateContractFxn = (valData) => async (dispatch) => {
    console.log(valData, "valdata")
    dispatch(showLoader())
    let {data} = await axios.post(generateContractUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const generateOfferLetterFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(generateOfferLetterUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};
export const reGenerateOfferLetterFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(reGenerateOfferLetterUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const requiredDocumentsFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(requiredDocumentsUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const getRequiredDocsFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData}
    }
    let {data} = await axios.get(requiredDocumentsUrl(), config);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error in loading data'
        })
    }
    return data;
};


export const palRequestFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(palRequestUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};
export const uploadPalFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(uploadPalUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};
export const checkDocumentRequirementFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(checkDocumentRequirementUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const uploadLoaFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(uploadLoaUrl(), valData)
    dispatch(hideLoader())
    return data;
}

export const uploadOfferLetterFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(uploadOfferLetterUrl(), valData)
    dispatch(hideLoader())
    return data;
}

export const uploadCoOpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(uploadCoOpUrl(), valData)
    dispatch(hideLoader())
    return data;
}

export const loaRequestFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(loaRequestUrl(), valData)
    dispatch(hideLoader())
    return data;
}

export const offerLetterRequestFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(offerLetterRequestUrl(), valData)
    dispatch(hideLoader())
    return data;
}

export const coopLetterRequestFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(coopLetterRequestUrl(), valData)
    dispatch(hideLoader())
    return data;
}



export const uploadReceiptFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(uploadReceiptUrl(), valData)
    dispatch(hideLoader())
    return data;
}

