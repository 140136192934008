import {apiUrl} from "../../../settings";

export const generateInvoiceUrl = () => {
    return apiUrl + '/invoice/add'
}

export const invoiceListUrl = () => {
    return apiUrl + '/invoice/list'
}

export const addPaymentUrl = () => {
    return apiUrl + '/payment/add'
}

export const paymentHistoryUrl = () => {
    return apiUrl + '/payment/list'
}

export const regenerateInvoiceUrl = () => {
    return apiUrl + '/invoice/update'
}

export const singleInvoiceUrl = () => {
    return apiUrl + '/invoice/single'
}

export const deleteInvoiceUrl = () => {
    return apiUrl + '/invoice/delete'
}

export const uploadInvoiceUrl = () => {
    return apiUrl + '/invoice/upload-invoice'
}

export const newUploadInvoiceUrl = () => {
    return apiUrl + '/invoice/add-invoice'
}

export const addInstallmentUrl = () => {
    return apiUrl + '/payment/installment'
}

export const installmentHistoryUrl = () => {
    return apiUrl + '/payment/getInstallment'
}

export const verifyInstallmentUrl = () => {
    return apiUrl + '/verify-installment'
}


