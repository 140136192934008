import React, {useEffect, useState} from "react";
import {Form, Modal, Card, notification} from "../../../components/Elements/appUtils";
import {conditionOptions} from "../../../components/_utils/_utils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {checkDocumentRequirementFxn, generateLoaFxn} from "../actions";
import {useDispatch} from "react-redux";

const LoaLetterModal = (props) => {
    const dispatch = useDispatch();
    const {
        visible, onClose, onSubmit,
        form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue},
        application
    } = props;


    const [isCoOpVisible, setIsCoOpVisible] = useState(false);
    const [isPracticumVisible, setIsPracticumVisible] = useState(false);
    const [isLengthFieldVisible, setIsLengthFieldVisible] = useState(false);


    useEffect(() => {
        const programType = getFieldValue("programType");
        const coOpDecision = getFieldValue("coOpDecision");
        setIsCoOpVisible(programType === "co-op");
        setIsLengthFieldVisible(programType === "co-op" && coOpDecision == 'Yes');
    }, [getFieldValue("programType"), getFieldValue("coOpDecision")]);

    useEffect(() => {
        const programType = getFieldValue("programType");
        const practicumDecision = getFieldValue("practicumDecision");
        setIsPracticumVisible(programType === "practicum");
        setIsLengthFieldVisible(programType === "practicum" && practicumDecision == 'Yes');
    }, [getFieldValue("programType"), getFieldValue("practicumDecision")]);

    const fields = [
        {
            key: "programType",
            label: "Select Program Type",
            type: "select",
            required: true,
            options: [
                {value: "co-op", name: "Co-Op"},
                {value: "practicum", name: "Practicum"},
                {value: 'none', name: "None Of The Above"}
            ],
            span: "col-md-12",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.name,
            onChange: (value) => {
                setFieldsValue({
                    programType: value,
                    coOpDecision: undefined,
                    practicumDecision: undefined
                });
            },
        },
        {
            key: "coOpDecision",
            label: "Choose Yes or No for Co-Op",
            type: "radio",
            required: true,
            options: conditionOptions,
            span: "col-md-12",
            hidden: !isCoOpVisible,
            keyAccessor: (option) => option.name,
            valueAccessor: (option) => option.name,
            onChange: (value) => {
                setFieldsValue({
                    coOpDecision: value,
                    length: undefined,
                    fieldOfWork: undefined
                });
            },
        },
        {
            key: "practicumDecision",
            label: "Choose Yes or No for Practicum",
            type: "radio",
            required: true,
            options: conditionOptions,
            span: "col-md-12",
            hidden: !isPracticumVisible,
            keyAccessor: (option) => option.name,
            valueAccessor: (option) => option.name,
            onChange: (value) => {
                setFieldsValue({
                    practicumDecision: value,
                    length: undefined,
                    fieldOfWork: undefined
                });
            },
        },
        {
            key: "length",
            label: "Length in (weeks)",
            type: "number",
            placeholder: "Enter length of the co-op program",
            // required: true,
            span: "col-md-12",
            hidden: !isLengthFieldVisible

        },
        {
            key: "fieldOfWork",
            label: "Field of Work",
            type: "text",
            placeholder: "Enter field of work",
            // required: true,
            span: "col-md-12",
            hidden: !isLengthFieldVisible

        },
        {
            key: "paymentStatus",
            label: "Payment Status",
            type: "radio",
            required: true,
            options: [
                {value: "Initial", name: "Initial Payment Paid"},
                {value: "Full", name: "Full Paid"}
            ],
            span: "col-md-12",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.name,
        },
        {
            key: "date",
            label: "Date",
            type: "dateOfBirth",
            span: "col-md-12",
            placeholder: "Enter Generate Date",
            required: true,
            onChange: (value) => {
                setFieldsValue({
                    date: value
                })
            }
        },

    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                let respDoc = await dispatch(checkDocumentRequirementFxn({
                    applicationId: application._id,
                    studentId: application.studentId,
                }))
                if (respDoc && respDoc.error) {
                    return
                }
                const resp = await dispatch(generateLoaFxn({
                    ...valData,
                    applicationId: application._id
                }));
                if (resp && resp.success) {
                    onSubmit();
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={"30%"}
            placement="right"
            title={(
                <>
                    <h5 className="card-action-title mb-0">
                        <span>Generate Loa Letter</span>
                    </h5>
                </>
            )}
        >
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    {fields.map((item, key) => (
                        !item.hidden &&
                        <div className={`${item.span ? item.span : "col-md-12"}`} key={key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={item}
                                keyAccessor={item.keyAccessor}
                                valueAccessor={item.valueAccessor}
                                onChange={item.onChange}
                            />
                        </div>
                    ))}
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                    Generate Loa Letter
                </button>
            </Form>
        </Modal>
    );
};

export default Form.create()(LoaLetterModal);
