import S from "string";

export {default as CountryCodeComponent} from "../Elements/CountryCodeComponent/index";
export const PureString = (value) => {
    return S(value).humanize().s;
};


export const RoundOf = (value) => {
    return parseFloat(value.toFixed(2));
};

export const genderOption = [{option: "Male"}, {option: "Female"}];

export const getAvatar = (name) => {
    return name ? name.charAt(0) : null;
};

export const months = {
    list: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    jsonList: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    },
};
export const getIntakeYear = () => {
    let intakeYear = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]
    return intakeYear
}

export const programLevel = [
    '1-Year Post-Secondary Certificate',
    '2-Year Undergraduate Diploma',
    '3-Year Undergraduate Advanced Diploma',
    '3-Year Bachelor\'s Degree',
    '4-Year Bachelor\'s Degree',
    '5-Year Bachelor\'s Degree',
    'Postgraduate Certificate / Master\'s Degree',
    'Doctoral Degree (Phd, M.D., ...)'
]
export const academicStatusList = [
    {name: 'Full Time', value: 'Full Time'},
    {name: 'Part Time', value: 'Part Time'}
]
export const typeOfProgramList = [
    {name: 'Career', value: 'Career'},
    {name: 'Vocational', value: 'Vocational'},
    {name: 'Academic', value: 'Academic'},
    {name: 'Professional', value: 'Professional'}
]

export const conditionOptions = [
    {name: 'Yes', value: true},
    {name: 'No', value: false}
]
export const trainingWeekOptions = [
    {name: '16', value: '16'},
    {name: '24', value: '24'}
]
export const schoolBreakOptions = [
    {name: 'Yes', value: true},
    {name: 'No', value: false}
]
export const documentSubName = (name) => {
    let exp = name.split('.').pop()
    let nameVal = name.split('.')[0]
    if (nameVal.length > 10) {
        nameVal = `${nameVal.substring(0, 10)}[...].${exp}`
        return nameVal
    } else {
        return name
    }
}

export const documentTypes = [
    {name: 'Passport', appIdRequired: false, uploadEnable: true, icon: 'bx bxs-id-card opacity-75'},
    {name: 'English Language Test Result', appIdRequired: false, uploadEnable: true, icon: 'bx bxs-book opacity-75'},
    {name: 'Educational Documents of Student', appIdRequired: false, uploadEnable: true, icon: 'bx bxs-graduation opacity-75'},
    {name: 'Offer Letter', appIdRequired: true, uploadEnable: false, icon: 'bx bxs-envelope opacity-75'},
    {name: 'LOA', appIdRequired: true, uploadEnable: false, icon: 'bx bxs-file-blank opacity-75'},
    // {name: 'GIC Certificate', appIdRequired: true, uploadEnable: true, icon: 'bx bxs-award opacity-75'},
    // {name: 'Enrollment Letter', appIdRequired: true, uploadEnable: false, icon: 'bx bxs-envelope opacity-75'},
    {name: 'Enrollment Contract', appIdRequired: true, uploadEnable: false, icon: 'bx bxs-file-doc opacity-75'},
    {name: 'PAL', appIdRequired: true, uploadEnable: false, icon: 'bx bxs-envelope opacity-75'},
]
export const ConditionOptionsForRaiseAComplaint = [
    {name: 'Student', value: 'student'},
    // {name: 'Complaint a Misbehaviour', value: 'Complaint a Misbehaviour'},
    {name: 'Others', value: 'others'}
]
export let initRights = [
    {type: "users", add: false, edit: false, view: false},
    {type: "courses", add: false, edit: false, view: false},
    {type: "student", add: false, edit: false, view: false, generate: false},
    {type: "esl", view: false, generate: false},
    {type: "agents", view: false},
    {type: "accounts", view: false, generate: false},
    {type: "documents", add: false, view: false}
]

export const documentChecklistTypes = [
    {name: 'Passport', appIdRequired: false, uploadEnable: true, icon: 'bx bxs-id-card opacity-75'},
    {name: 'English Language Test Result', appIdRequired: false, uploadEnable: true, icon: 'bx bxs-book opacity-75'},
    {name: 'Educational Documents of Student', appIdRequired: false, uploadEnable: true, icon: 'bx bxs-graduation opacity-75'},
    // {name: 'GIC Certificate', appIdRequired: true, uploadEnable: true, icon: 'bx bxs-award opacity-75'},
    // {name: 'Enrollment Letter', appIdRequired: true, uploadEnable: false, icon: 'bx bxs-envelope opacity-75'},
    {name: 'Enrollment Contract', appIdRequired: true, uploadEnable: false, icon: 'bx bxs-file-doc opacity-75'},
]
