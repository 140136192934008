import React, {useEffect} from "react"
import {Form, Modal, Card, notification} from "../../../components/Elements/appUtils";
import {conditionOptions, schoolBreakOptions, trainingWeekOptions} from "../../../components/_utils/_utils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {addStudentFxn, generateCo_opFxn, generateOfferLetterFxn} from "../actions";
import {useDispatch} from "react-redux";
import _ from "lodash"

let typesField = [
    {name: 'Training', key: 'training', required: true},
    {name: 'Practicum', key: 'practicum', required: false},
    {name: '2nd Training', key: 'secondTraining', required: false},
    {name: 'Co-op', key: 'coop', required: true}
]
const CoopLetterGenerateModal = (props) => {
    let dispatch = useDispatch();
    let {
        visible, onClose, onSubmit,
        form: {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue}, applicationId, application
    } = props;

    useEffect(() => {
        setDefaultData()
    }, [])

    let setDefaultData = () => {
        setFieldsValue({
            coopGeneratedDate: application.coopGeneratedDate || null,
            totalTrainingWeek: application.totalTrainingWeek || null,
            totalTrainingHours: application.totalTrainingHours || null,

            trainingWeek: application.trainingWeek || null,
            trainingHours: application.trainingHours || null,
            trainingFromDate: application.trainingFromDate || null,
            trainingToDate: application.trainingToDate || null,

            practicumWeek: application.practicumWeek || null,
            practicumHours: application.practicumHours || null,
            practicumFromDate: application.practicumFromDate || null,
            practicumToDate: application.practicumToDate || null,

            secondTrainingWeek: application.secondTrainingWeek || null,
            secondTrainingHours: application.secondTrainingHours || null,
            secondTrainingFromDate: application.secondTrainingFromDate || null,
            secondTrainingToDate: application.secondTrainingToDate || null,

            coopWeek: application.coopWeek || null,
            coopHours: application.coopHours || null,
            coopFromDate: application.coopFromDate || null,
            coopToDate: application.coopToDate || null,
            schoolBreak: application.schoolBreak || false,
        })
        setTimeout(() => {
            setFieldsValue({
                breakFromDate: application.breakFromDate || null,
                breakToDate: application.breakToDate || null,
            })
        }, 200)
    }

    const fieldObj = {
        fields1: [
            {
                key: "coopGeneratedDate",
                label: "Coop Generate Date",
                type: "dateOfBirth",
                placeholder: "Choose Coop Generate Date",
                required: true,
                span: "col-md-6",
                onChange: (value) => {
                    setFieldsValue({
                        coopGeneratedDate: value
                    });
                },
            },
        ],
        fields: [
            {
                key: "totalTrainingWeek",
                label: "Total Training Week",
                placeholder: "Enter Total Training Week",
                required: true,
                type: "number",
                span: "col-md-6",
            },
            {
                key: "totalTrainingHours",
                label: "Enter Total Training Hours",
                placeholder: "Enter Total Training Hours",
                required: true,
                type: "number",
                span: "col-md-6",
            },
        ],
        breakFields: [
            {
                key: "schoolBreak",
                label: "Do you want to add School Break",
                type: "radio",
                required: true,
                options: schoolBreakOptions,
                span: "col-md-3",
                keyAccessor: (option) => option.value,
                valueAccessor: (option) => option.name,
                onChange: (value) => {
                    setFieldsValue({
                        schoolBreak: value
                    });
                },
            },
            {
                key: "breakFromDate",
                label: "Break Start Date",
                type: "dateOfBirth",
                span: "col-md-3",
                placeholder: "Enter Break Start Date",
                required: true,
                onChange: (value) => {
                    setFieldsValue({
                        breakFromDate: value
                    })
                },
                hidden: !getFieldValue('schoolBreak')
            },
            {
                key: "breakToDate",
                label: "Break End Date",
                type: "dateOfBirth",
                span: "col-md-3",
                placeholder: "Enter Break End Date",
                required: true,
                onChange: (value) => {
                    setFieldsValue({
                        breakToDate: value
                    })
                },
                hidden: !getFieldValue('schoolBreak')
            }
        ]
    }


    const fields = [
        {
            key: "totalTrainingWeek",
            label: "Total Training Week",
            required: true,
            type: "number",
            span: "col-md-6",
        },
        {
            key: "totalTrainingHours",
            label: "Enter Total Training Hours",
            placeholder: "Enter Total Training Hours",
            required: true,
            type: "number",
            span: "col-md-6",
        },
    ]

    let getFields = () => {
        let trainingFieldTypes = [
            {name: 'Training', key: 'training', required: true},
            {name: 'Practicum', key: 'practicum', required: false}
        ]
        let newFields = [];
        let fieldsTypes = [
            {name: "Weeks", type: 'text', key: "Week"},
            {name: "Hours", type: 'number', key: "Hours"},
            {name: "From Date", type: 'dateOfBirth', key: "FromDate"},
            {name: "To Date", type: 'dateOfBirth', key: "ToDate"},
        ]
        _.each(trainingFieldTypes, (item) => {
            _.each(fieldsTypes, (f) => {
                let obj = {
                    key: item.key + f.key,
                    label: `Enter ${item.name} ${f.name}`,
                    placeholder: `Enter ${item.name} ${f.name}`,
                    required: item.required,
                    type: f.type,
                    span: "col-md-3",
                }
                if (f.key == "Week") {
                    obj.label = `Enter ${item.name} ${f.name} (Ex :- 1-16 Weeks)`
                    obj.placeholder = `Enter ${item.name} ${f.name} (Ex :- 1-16 Weeks)`
                }
                let fieldName = item.key + f.key
                if (f.type == 'dateOfBirth') {
                    obj.onChange = (value) => {
                        setFieldsValue({
                            [fieldName]: value
                        })
                    }
                }
                newFields.push(obj)
            })
        })
        return newFields
    }
    let getFieldsCoopField = () => {
        let coopFieldTypes = [
            {name: '2nd Training', key: 'secondTraining', required: false},
            {name: 'Co-op', key: 'coop', required: true}
        ]
        let newFields = [];
        let fieldsTypes = [
            {name: "Weeks", type: 'number', key: "Week"},
            {name: "Hours", type: 'number', key: "Hours"},
            {name: "From Date", type: 'dateOfBirth', key: "FromDate"},
            {name: "To Date", type: 'dateOfBirth', key: "ToDate"},
        ]
        _.each(coopFieldTypes, (item) => {
            _.each(fieldsTypes, (f) => {
                let obj = {
                    key: item.key + f.key,
                    label: `Enter ${item.name} ${f.name}`,
                    placeholder: `Enter ${item.name} ${f.name}`,
                    required: item.required,
                    type: f.type,
                    span: "col-md-3",
                }
                let fieldName = item.key + f.key
                if (f.type == 'dateOfBirth') {
                    obj.onChange = (value) => {
                        setFieldsValue({
                            [fieldName]: value
                        })
                    }
                }
                newFields.push(obj)
            })
        })
        return newFields
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                const resp = await dispatch(generateCo_opFxn({
                    ...valData,
                    applicationId
                }));
                if (resp && resp.success) {
                    onSubmit()
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={'65%'}
            placement='right'
            title={(
                <>
                    <h5 className="card-action-title mb-0">
                        <span>Generate Coop Letter</span>
                    </h5>
                </>
            )}>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    {fieldObj.fields1.map((item, key) => (
                        <div className={`${item.span ? item.span : "col-md-12"}`} key={key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={item}
                                keyAccessor={item.keyAccessor}
                                valueAccessor={item.valueAccessor}
                                onChange={item.onChange}
                            />
                        </div>
                    ))}
                </div>
                <div className="row mt-3">
                    {fieldObj.fields.map((item, key) => (
                        <div className={`${item.span ? item.span : "col-md-12"}`} key={key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={item}
                                keyAccessor={item.keyAccessor}
                                valueAccessor={item.valueAccessor}
                                onChange={item.onChange}
                            />
                        </div>
                    ))}
                </div>
                <div className="row">

                    {getFields().map((item, key) => (
                        <div className={`${item.span ? item.span : "col-md-12"}`} key={key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={item}
                                keyAccessor={item.keyAccessor}
                                valueAccessor={item.valueAccessor}
                                onChange={item.onChange}
                            />
                        </div>
                    ))}
                </div>
                <div className="row">

                    {fieldObj.breakFields.map((item, key) => (
                        <div className={`${item.span ? item.span : "col-md-12"}`} key={key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={item}
                                keyAccessor={item.keyAccessor}
                                valueAccessor={item.valueAccessor}
                                onChange={item.onChange}
                            />
                        </div>
                    ))}
                </div>
                <div className="row">

                    {getFieldsCoopField().map((item, key) => (
                        <div className={`${item.span ? item.span : "col-md-12"}`} key={key}>
                            <GetEachFormFields
                                getFieldDecorator={getFieldDecorator}
                                getFieldError={getFieldError}
                                {...props.form}
                                item={item}
                                keyAccessor={item.keyAccessor}
                                valueAccessor={item.valueAccessor}
                                onChange={item.onChange}
                            />
                        </div>
                    ))}
                </div>

                <button type="submit" className="btn btn-primary mt-3">
                    Generate Coop Letter
                </button>
            </Form>
        </Modal>
    )
}
export default Form.create()(CoopLetterGenerateModal);
