import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {dateFormat, Form, notification} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {courseListFxn, singleCourseFxn} from "../../course/action";
import axios from "axios";
import {apiUrl} from "../../../settings";
import {getToken} from "../../../request";
import moment from "moment";
import _ from "lodash";
import {conditionOptions} from "../../../components/_utils/_utils";
import {getAllAgentFxn} from "../../manager/action";
import {addActivityFxn, updateIntakeFxn} from "../actions";

const initState = {
    courseName: "",
    courseCode: "",
    duration: "",
    intake: [],
    startDate: undefined,
    endDate: undefined,
    description: "",
};

const IntakeEdit = (props) => {
    const {
        form: {getFieldDecorator, getFieldError, setFieldsValue},
    } = props;
    let {onSubmit, application} = props;
    const [intakeList, setIntakeList] = useState([]);
    let [intake, setIntake] = useState({});

    useEffect(() => {
        if (application) {
            let obj = {
                courseName: application.courseName,
                startDate: application.startDate ? application.startDate : null,
                endDate: application.endDate ? application.endDate : null,
                expirationDate: application.expirationDate ? application.expirationDate : null,
            }
            if (application && application.intake && application.intake.month) {
                obj.intakeTemp = `${application.intake.month}-${application.intake.year}`
            }
            setFieldsValue(obj);
            events.loadCourseData();
        }
    }, [application])

    const events = {
        loadCourseData: async () => {
            let {data} = await dispatch(singleCourseFxn({_id: application.courseId}));
            if (data) {
                let intakeArr = []
                _.each(data.intakeList, (item) => {
                    if (item) {
                        item.value = `${item.month}-${item.year}`
                        item.label = `${item.month}, ${item.year}`
                        intakeArr.push(item)
                    }
                })
                setIntakeList(intakeArr);
            }
        },

        chooseIntake: (value) => {
            let findIntake = _.find(intakeList, (item) => {
                return item && item.value == value
            })
            if (findIntake) {
                setFieldsValue({
                    intakeTemp: value,
                    startDate: findIntake.startDate ? findIntake.startDate : undefined,
                    endDate: findIntake.endDate ? findIntake.endDate : undefined,
                    expirationDate: findIntake.startDate ? moment(findIntake.startDate, dateFormat).add(15, 'day').format(dateFormat) : undefined,
                })
            }
            if (value) {
                let intakeVal = value.split('-')
                let intake = {
                    month: intakeVal[0],
                    year: intakeVal[1]
                }
                setIntake(intake)
            } else {
                setIntake({})
            }
        },
    };

    const inputSchema = [
        {
            key: "courseName",
            label: "Course",
            type: "text",
            required: true,
            disabled: true,
            span: "col-md-6",
        },
        {
            key: "intakeTemp",
            label: "Intake",
            type: "select",
            options: intakeList,
            span: "col-md-6",
            placeholder: "Select Intake",
            required: true,
            keyAccessor: (x) => x.value,
            valueAccessor: (x) => `${x.label}`,
            onChange: (x) => {
                events.chooseIntake(x);
            },
        },
        {
            key: "startDate",
            label: "Start Date",
            type: "date",
            span: "col-md-4",
            placeholder: "Start Date",
            disabled: true,
        },
        {
            key: "endDate",
            label: "Completion Date",
            type: "date",
            span: "col-md-4",
            placeholder: "Completion Date",
            disabled: true,
        },
        {
            key: "expirationDate",
            label: "Expiration Date",
            type: "date",
            span: "col-md-4",
            placeholder: "Choose Expiration Date",
            disabled: true,
            onChange: (value) => {
                setFieldsValue({
                    expirationDate: value,
                });
            },
        },
    ];

    const dispatch = useDispatch();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                try {
                    valData = {
                        ...valData,
                        intake: intake,
                        applicationId: application._id
                    };
                    let data = await dispatch(updateIntakeFxn({...valData}));
                    if (data && data.message) {
                        onSubmit();
                    }
                } catch (error) {
                    console.error("Failed to update course:", error);
                }
            } else {
                notification.warning({message: "Fill All Required Fields"});
            }
        });
    };

    return (
        <div className="row g-6">
            <div className="col-12">
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        {inputSchema.map((field, key) => (
                            <div
                                className={`${field.span ? field.span : "col-md-6"} mb-3`}
                                key={key}
                            >
                                <GetEachFormFields
                                    getFieldDecorator={getFieldDecorator}
                                    getFieldError={getFieldError}
                                    {...props.form}
                                    item={field}
                                    keyAccessor={field.keyAccessor}
                                    valueAccessor={field.valueAccessor}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Form.create()(IntakeEdit);
