import {customAxios as axios, getToken} from "../../../request";

import {notification} from "../../../components/Elements/appUtils";
import {hideLoader, showLoader} from "../../../actions/loader";
import {
    addPaymentUrl,
    generateInvoiceUrl,
    invoiceListUrl,
    paymentHistoryUrl,
    regenerateInvoiceUrl,
    singleInvoiceUrl,
    addInstallmentUrl,
    installmentHistoryUrl, verifyInstallmentUrl,
    deleteInvoiceUrl, uploadInvoiceUrl, newUploadInvoiceUrl
} from "../api";
import {appInvoiceListUrl} from "../../student/api";

export const generateInvoiceFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(generateInvoiceUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const invoiceListFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.get(invoiceListUrl(), {params: valData})
    dispatch(hideLoader())
    return data.data;
}


export const addPaymentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addPaymentUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const paymentHistoryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.get(paymentHistoryUrl(), valData)
    dispatch(hideLoader())
    return data.data;
}

export const regenerateInvoiceFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(regenerateInvoiceUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const singleInvoiceFxn = (valData) => async (dispatch) => {
    console.log(valData, "val")
    dispatch(showLoader())
    let {data} = await axios.post(singleInvoiceUrl(), valData)
    dispatch(hideLoader())
    return data.data;
}

export const addInstallmentFxn = (valData) => async (dispatch) => {
    console.log('add installment ----', valData)
    dispatch(showLoader())
    let {data} = await axios.post(addInstallmentUrl(), valData);
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
};

export const installmentHistoryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(installmentHistoryUrl(), valData)
    dispatch(hideLoader())
    return data.data;
}

export const verifyInstallmentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(verifyInstallmentUrl(), valData)
    dispatch(hideLoader())
    return data;
}

export const deleteInvoiceFxn = async (filters) => {
    let config = {
        params: {...filters}
    }
    let {data} = await axios.delete(deleteInvoiceUrl(), config);
    return data;
};

export const uploadInvoiceFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(uploadInvoiceUrl(), valData)
    dispatch(hideLoader())
    return data;
}

export const newUploadInvoiceFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(newUploadInvoiceUrl(), valData)
    dispatch(hideLoader())
    return data;
}