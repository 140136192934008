import React, {useState} from "react"
import {Modal, notification, InputBox} from "../../../components/Elements/appUtils";
import CourseEdit from "../components/courseEdit";
import {uploadOfferLetterFxn} from "../actions";
import {uploadInvoiceFxn} from "../../Invoices/actions";
import {useDispatch} from "react-redux";

const UploadOfferLetterModal = (props) => {
    let {visible, onClose, application, onSubmit} = props;
    let dispatch = useDispatch()
    const [offerLetter, setOfferLetter] = useState({});
    const uploadOffer = async (e) => {
        e.preventDefault()
        /*     let respDoc = await dispatch(checkDocumentRequirementFxn({
                 applicationId: appId,
                 studentId: stdId,
             }))
             if (respDoc && respDoc.error) {
                 return
             }*/

        if (!offerLetter || (offerLetter && !offerLetter.name)) {
            notification.warning({
                message: 'Please choose Offer'
            });
            return
        }

        let fd = new FormData();
        let obj = {}
        obj.type = 'Offer_Letter'
        obj.applicationId = application._id;
        obj.studentId = application.studentId;
        fd.append('obj', JSON.stringify(obj));
        if (offerLetter && offerLetter.name) {
            fd.append('offerLetter', offerLetter)
        }
        let resp = await dispatch(uploadOfferLetterFxn(fd));
        if (resp && resp.success) {
            onSubmit()
        }
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setOfferLetter(file)
        }
    };


    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={'30%'}
            placement='right'
            title={'Upload Offer'}>
            <InputBox>
                <input type={'file'}
                       name={'offerLetter'} id={'offerLetter'}
                       className={'form-control'} onChange={(e) => handleFileChange(e)}
                />
            </InputBox>

            <div className={'btn-box mt-5'}>
                <button type="button" className="btn btn-label-danger btn-md"
                        onClick={onClose}
                ><span>No</span></button>
                <button type="button" className="btn btn-label-primary btn-md"
                        onClick={(e) => {
                            uploadOffer(e)
                        }}><span>Yes</span>
                </button>
            </div>
        </Modal>
    )
}
export default UploadOfferLetterModal;
