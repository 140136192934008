import React, {useState} from "react"
import {Modal, notification, InputBox} from "../../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {uploadReceiptFxn} from "../actions";

const UploadReceiptModal = (props) => {
    let {visible, onClose, application, invoice, onSubmit} = props;
    let dispatch = useDispatch()
    const [receiptLetter, setReceiptLetter] = useState({});
    // console.log(application, "-------", invoice, "---------")

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setReceiptLetter(file);
            // console.log("Selected File:", file);
        } else {
            // console.error("No file selected.");
        }
    };

    const uploadReceipt = async (e) => {
        e.preventDefault()
        /*     let respDoc = await dispatch(checkDocumentRequirementFxn({
                 applicationId: appId,
                 studentId: stdId,
             }))
             if (respDoc && respDoc.error) {
                 return
             }*/

        if (!receiptLetter || (receiptLetter && !receiptLetter.name)) {
            notification.warning({
                message: 'Please choose Invoice'
            });
            return
        }

        let fd = new FormData();
        let obj = {}
        obj.type = 'RECEIPT'
        obj.applicationId = application._id;
        obj.studentId = application.studentId;
        obj.invoiceId = invoice.invoices._id;
        obj.invoice = invoice.invoices
        // obj.receipt = invoice.invoices.receipt
        fd.append('obj', JSON.stringify(obj));
        if (receiptLetter && receiptLetter.name) {
            fd.append('receiptLetter', receiptLetter)
            console.log("FormData receiptLetter:", receiptLetter);
        }
        let resp = await dispatch(uploadReceiptFxn(fd));
        if (resp && resp.success) {
            onClose()
        }
    };


    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={'30%'}
            placement='right'
            title={'Upload Receipt'}>
            <InputBox>
                <input type={'file'}
                       name={'receiptLetter'} id={'receiptLetter'}
                       className={'form-control'} onChange={(e) => handleFileChange(e)}
                />
            </InputBox>

            <div className={'btn-box mt-5'}>
                <button type="button" className="btn btn-label-danger btn-md"
                        onClick={onClose}
                ><span>No</span></button>
                <button type="button" className="btn btn-label-primary btn-md"
                        onClick={(e) => {
                            uploadReceipt(e)
                        }}><span>Yes</span>
                </button>
            </div>
        </Modal>
    )
}
export default UploadReceiptModal;
